import { TSearchList } from "@/redux/search/list/types";

const initialState: TSearchList = {
  projects: null,
  estates: null,
  stats: null,
  // banners: {
  //   top: null,
  //   inline: null,
  //   side: null,
  //   side_mobile: null,
  //   secondary: null,
  // },
};

export default initialState;
