import { FiltersInput } from "@/types/api/complex";
import { CurrencyKeys, EstateTypeKeys } from "@/types/api/derived";

export const SITE_NAME = "Flat Zone";
export const SITE_ID = "flatzone.sk";

export const DEV = process.env.NEXT_PUBLIC_DEV === "true";
export const ENVIRONMENT = DEV ? "dev" : "live";
export const LANG = "SK";
export const COUNTRY = LANG === "SK" ? "Slovensko" : "Česká republika";
export const CURRENCY = LANG === "SK" ? "€" : "Kč";
export const CURRENCY_CODE: CurrencyKeys = LANG === "SK" ? "EUR" : "CZK";
export const LEVELS = [
  "street",
  "cadastral_area",
  "neighborhood",
  "borough",
  "city",
  "district",
  "region",
  "country",
];

export const DEFAULT_PROPERTY_FILTER: FiltersInput = {
  type: ["FLAT", "HOUSE"],
  offerType: ["SALE"],
  roomCount: [],
};

export const DEFAULT_ESTATE_TYPES: Record<string, EstateTypeKeys> = {
  byty: "FLAT",
  domy: "HOUSE",
};

export const DEFAULT_ANNUAL_INTEREST_RATE = 3.9;

export const SQUARE_METER = "m²";

export const MARKERS: [min: number, max: number][] = [
  [0, 5],
  [6, 25],
  [26, 50],
  [51, 75],
  [76, 100],
  [101, 1_000_000],
];
