import "@/styles/index.css";
import { useEffect } from "react";
import { AppProps } from "next/app";
import { FunctionComponent } from "react";
import { Provider } from "react-redux";
import store from "@/redux/store";
import * as CookieConsent from "vanilla-cookieconsent";
import pluginConfig from "@/utils/cookies/CookieConsentConfig";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import "@/styles/cookieconsent.css";

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || "";

const App: FunctionComponent<AppProps> = ({ Component, pageProps }) => {
  useEffect(() => {
    CookieConsent.run(pluginConfig);
  }, []);

  return (
    <Provider store={store}>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{
            display: "none",
            visibility: "hidden",
          }}
        />
      </noscript>

      <Component {...pageProps} />
    </Provider>
  );
};
export default App;
