import q from "@/utils/network/gql/q";

export type Vars = {
  country: "CZ" | "SK";
  term: string;
  levels: string[];
};

export const VARS = {
  country: "String",
  term: "String!",
  levels: "[String!]!",
};

const query = (name: string) => q(name, VARS);

export default query;
