import { combineReducers } from "@reduxjs/toolkit";
import preferencesReducer from "./user/preferences";
import userAuthReducer from "./user/auth";
import suggestReducer from "./search/suggest";
import searchSharedReducer from "./search/shared";
import searchListReducer from "./search/list";
import searchDetailReducer from "./search/detail";

export const rootReducer = combineReducers({
  ...preferencesReducer,
  ...userAuthReducer,
  ...suggestReducer,
  ...searchSharedReducer,
  ...searchListReducer,
  ...searchDetailReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
