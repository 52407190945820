import createRequest from "../createRequest";
import parseJSON from "../parsers/parseJSON";
import gql from "@/utils/network/gql/gql";
import { Errors } from "@/types/api/complex";

const graphRequest = <Data, Vars>(
  url: RequestInfo,
  query: string,
  variables: Vars,
  options?: RequestInit,
) => {
  const queryObj = gql(query)<Vars>(variables);
  const mergedOptions: RequestInit = {
    ...(options || {}),
    body: JSON.stringify(queryObj),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(options?.headers || {}),
    },
  };

  return createRequest<{ data: Data; errors?: Errors }>(
    url,
    parseJSON,
    mergedOptions,
  );
};

export default graphRequest;
