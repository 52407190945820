import { SearchType, TSearchShared } from "@/redux/search/shared/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { Filters, SearchQuery, Settings } from "@/types/api/complex";
import {
  DispositionKeys,
  EstateTypeKeys,
  OfferTypeKeys,
  SortOptionKeys,
  View,
} from "@/types/api/derived";

export const _setSearchSharedQuery = (
  s: TSearchShared,
  {
    payload: { query, settings },
  }: PayloadAction<{ query: SearchQuery; settings: Settings }>,
) => {
  s.query = query;
  s.settings = settings;
};

export const _setSearchSharedFilters = (
  s: TSearchShared,
  { payload: filters }: PayloadAction<Filters>,
) => {
  s.filters = filters;
  s.hasChanged = true;
};

export const _setSearchSharedView = (
  s: TSearchShared,
  { payload: view }: PayloadAction<View>,
) => {
  (s.settings as any).current.view = view;
  s.hasChanged = true;
};

export const _setSearchSharedPage = (
  s: TSearchShared,
  { payload: page }: PayloadAction<number>,
) => {
  (s.settings as any).current.page = page;
  s.hasChanged = true;
};

export const _setSearchSharedSort = (
  s: TSearchShared,
  { payload: sort }: PayloadAction<SortOptionKeys>,
) => {
  (s.settings as any).current.sort = sort;
  s.hasChanged = true;
};

export const _setSearchSharedOfferTypes = (
  s: TSearchShared,
  { payload: offerTypes }: PayloadAction<OfferTypeKeys[]>,
) => {
  (s.settings as any).current.offerTypes = offerTypes;
  s.hasChanged = true;
};

export const _setSearchSharedEstateTypes = (
  s: TSearchShared,
  { payload: estateTypes }: PayloadAction<EstateTypeKeys[]>,
) => {
  (s.settings as any).current.estateTypes = estateTypes;
  s.hasChanged = true;
};

export const _setSearchSharedDispositions = (
  s: TSearchShared,
  { payload: dispositions }: PayloadAction<DispositionKeys[]>,
) => {
  (s.settings as any).current.dispositions = dispositions;
  s.hasChanged = true;
};

export const _setSearchSharedLoading = (
  s: TSearchShared,
  { payload: loading }: PayloadAction<boolean>,
) => {
  s.loading = loading;
};

export const _setSearchSharedType = (
  s: TSearchShared,
  { payload: type }: PayloadAction<SearchType>,
) => {
  s.type = type;
};

export const _setSearchSharedNumOfProjects = (
  s: TSearchShared,
  { payload: numOfProjects }: PayloadAction<TSearchShared["numOfProjects"]>,
) => {
  s.numOfProjects = numOfProjects;
};

export const _setSearchSharedHasChanged = (
  s: TSearchShared,
  { payload: hasChanged }: PayloadAction<TSearchShared["hasChanged"]>,
) => {
  s.hasChanged = hasChanged;
};

export const _setSearchSharedPageObj = (
  s: TSearchShared,
  { payload: data }: PayloadAction<Partial<TSearchShared["page"]>>,
) => {
  s.page = {
    ...s.page,
    ...data,
  };
};

export const _setSearchSharedIgnoreEstateImpression = (
  s: TSearchShared,
  { payload: ignoreEstateImpression }: PayloadAction<boolean>,
) => {
  s.ignoreEstateImpression = ignoreEstateImpression;
};

export const _setSearchSharedLastPage = (
  s: TSearchShared,
  { payload: lastPage }: PayloadAction<string>,
) => {
  s.lastPage = lastPage;
};
