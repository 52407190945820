type Parser = (response: Response) => Promise<any>;

const dev = process.env.NEXT_PUBLIC_DEV;

const createRequest = <Data>(
  url: RequestInfo,
  parser: Parser,
  options?: RequestInit,
): Promise<Data> => {
  if (dev) {
    console.log("DEBUG", {
      url,
      ...options,
      body:
        options?.body && typeof options.body === "string"
          ? JSON.parse(options.body)
          : null,
    });
  }

  return fetch(url, options)
    .then((response) => {
      if (response.ok) {
        try {
          return parser(response);
        } catch (e: any) {
          const message = e?.message || e;

          if (dev) console.log("ERROR", message);
          throw new Error(message);
        }
      }

      const message = response.statusText || "Something went wrong";

      if (dev) console.log("ERROR", message);
      throw new Error(message);
    })
    .catch(console.error);
};

export default createRequest;
