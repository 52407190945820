import query, { Vars } from "@/api/queries/querySuggest";
import fieldSuggest from "@/api/fields/fieldSuggest";
import f from "@/utils/network/gql/f";
import graphDatabaseRequest from "@/utils/network/request/presets/graphDatabaseRequest";

const queryString = query("fetchSuggestions")((vars) => [
  fieldSuggest(vars)([
    "name",
    "level",
    "extraInfo",
    f("address")(["country", "region", "city", "cadastralArea", "street"]),
  ]),
]);

export type Data = {
  addressSuggestions: {
    name: string;
    level: string;
    extraInfo: string;
    address: {
      country: string;
      region: string;
      city: string;
      cadastralArea: string;
      street: string;
    };
  }[];
};

const fetchSuggestions = (vars: Vars) =>
  graphDatabaseRequest<Data, Vars>(queryString, vars);

export default fetchSuggestions;
