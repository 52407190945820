import { OperationVars } from "@/types/operation";
import { Vars } from "@/api/queries/querySuggest";
import f from "@/utils/network/gql/f";

const fieldSuggest = (vars: OperationVars<Vars>) =>
  f("addressSuggestions", [
    ["country", vars.country],
    ["term", vars.term],
    ["levels", vars.levels],
  ]);

export default fieldSuggest;
