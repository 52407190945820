type DataEvent = Record<string, string | any> | null;

declare global {
  interface Window {
    dataLayer?: {
      push: (event: DataEvent) => void;
    };
  }
}

const pushEvent = (event: DataEvent) => {
  if (event !== null) {
    if (typeof window !== "undefined") {
      window?.dataLayer?.push(event);
    }
  }
};

export default pushEvent;
