const getOpname = /(query|mutation) ?([\w\d-_]+)? ?\(.*?\)? \{/;

const gql = (q: string | TemplateStringsArray) => {
  const query = (Array.isArray(q) ? q.join("") : q) as string;
  const name = getOpname.exec(query);

  return <Vars>(vars: Vars) => {
    const data: {
      query: string;
      variables: string | Record<string, never>;
      operationName?: string;
    } = {
      query,
      variables: vars ? JSON.stringify(vars) : {},
    };

    if (name && name.length) {
      const operationName = name[2];
      if (operationName) data.operationName = name[2];
    }

    return data;
  };
};

export default gql;
