import { createSlice } from "@reduxjs/toolkit";
import initialState from "@/redux/user/preferences/initialState";
import { _setUserPreferencesCookies } from "@/redux/user/preferences/reducers";

const name = "userPreferences" as const;

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setUserPreferencesCookies: _setUserPreferencesCookies,
  },
});

export const { setUserPreferencesCookies } = slice.actions;

export default {
  [name]: slice.reducer,
};
