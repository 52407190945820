import { createSlice } from "@reduxjs/toolkit";
import initialState from "@/redux/search/detail/initialState";
import {
  _setSearchDetailData,
  _setSearchDetailEstates,
} from "@/redux/search/detail/reducers";

const name = "searchDetail" as const;

const slice = createSlice({
  name,
  initialState,
  reducers: {
    // @ts-ignore
    setSearchDetailEstates: _setSearchDetailEstates,
    // @ts-ignore
    setSearchDetailData: _setSearchDetailData,
  },
});

export const { setSearchDetailEstates, setSearchDetailData } = slice.actions;

export default {
  [name]: slice.reducer,
};
