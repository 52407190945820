import { createSlice } from "@reduxjs/toolkit";
import { TSearchSuggest } from "@/redux/search/suggest/types";
import {
  _setActiveSuggestion,
  _setResults,
  _suggestLocality,
} from "@/redux/search/suggest/reducers";

const name = "searchSuggest" as const;

const initialState: TSearchSuggest = {
  query: null,
  results: [],
  activeIndex: null,
};

export const searchSuggestSlice = createSlice({
  name,
  initialState,
  reducers: {
    suggestLocality: _suggestLocality,
    setActiveSuggestion: _setActiveSuggestion,
    setResults: _setResults,
  },
});

export const { suggestLocality, setActiveSuggestion, setResults } =
  searchSuggestSlice.actions;

export default {
  [name]: searchSuggestSlice.reducer,
};
