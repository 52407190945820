import { createSlice } from "@reduxjs/toolkit";
import initialState from "@/redux/search/shared/initialState";
import {
  _setSearchSharedDispositions,
  _setSearchSharedEstateTypes,
  _setSearchSharedFilters,
  _setSearchSharedHasChanged,
  _setSearchSharedLoading,
  _setSearchSharedNumOfProjects,
  _setSearchSharedOfferTypes,
  _setSearchSharedPage,
  _setSearchSharedPageObj,
  _setSearchSharedQuery,
  _setSearchSharedSort,
  _setSearchSharedType,
  _setSearchSharedView,
  _setSearchSharedIgnoreEstateImpression,
  _setSearchSharedLastPage,
} from "@/redux/search/shared/reducers";

const name = "searchShared" as const;

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setSearchSharedQuery: _setSearchSharedQuery,
    setSearchSharedFilters: _setSearchSharedFilters,
    setSearchSharedView: _setSearchSharedView,
    setSearchSharedSort: _setSearchSharedSort,
    setSearchSharedPage: _setSearchSharedPage,
    setSearchSharedOfferTypes: _setSearchSharedOfferTypes,
    setSearchSharedEstateTypes: _setSearchSharedEstateTypes,
    setSearchSharedDispositions: _setSearchSharedDispositions,
    setSearchSharedLoading: _setSearchSharedLoading,
    setSearchSharedType: _setSearchSharedType,
    setSearchSharedNumOfProjects: _setSearchSharedNumOfProjects,
    setSearchSharedHasChanged: _setSearchSharedHasChanged,
    setSearchSharedPageObj: _setSearchSharedPageObj,
    setSearchSharedIgnoreEstateImpression:
      _setSearchSharedIgnoreEstateImpression,
    setSearchSharedLastPage: _setSearchSharedLastPage,
  },
});

export const {
  setSearchSharedQuery,
  setSearchSharedFilters,
  setSearchSharedView,
  setSearchSharedSort,
  setSearchSharedPage,
  setSearchSharedOfferTypes,
  setSearchSharedEstateTypes,
  setSearchSharedDispositions,
  setSearchSharedLoading,
  setSearchSharedType,
  setSearchSharedNumOfProjects,
  setSearchSharedHasChanged,
  setSearchSharedPageObj,
  setSearchSharedIgnoreEstateImpression,
  setSearchSharedLastPage,
} = slice.actions;

export default {
  [name]: slice.reducer,
};
