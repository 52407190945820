import { createSlice } from "@reduxjs/toolkit";
import initialState from "@/redux/user/auth/initialState";
import {
  _setUserToken,
  _setUserRefreshToken,
  _setUserData,
  _setIsLoginFormOpen,
  _setCookies,
} from "@/redux/user/auth/reducers";

const name = "userAuth" as const;

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setUserToken: _setUserToken,
    setUserRefreshToken: _setUserRefreshToken,
    setUserData: _setUserData,
    setIsLoginFormOpen: _setIsLoginFormOpen,
    setCookies: _setCookies,
  },
});

export const {
  setUserToken,
  setUserRefreshToken,
  setUserData,
  setIsLoginFormOpen,
  setCookies,
} = slice.actions;

export default {
  [name]: slice.reducer,
};
