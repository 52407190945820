import { TUserPreferences } from "@/redux/user/preferences/types";
import { PayloadAction } from "@reduxjs/toolkit";

export const _setUserPreferencesCookies = (
  s: TUserPreferences,
  { payload: cookies }: PayloadAction<TUserPreferences["cookies"]>,
) => {
  if (typeof localStorage !== "undefined")
    localStorage.setItem("cookies", `${cookies}`);
  s.cookies = cookies;
};
