import { TSearchSuggest } from "@/redux/search/suggest/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { Maybe } from "@/types/general";
import { setResults } from "@/redux/search/suggest/index";
import store from "@/redux/store";
import fetchSuggestions from "@/api/fetchSuggestions";
import { LANG, LEVELS } from "@/data/constants";

export const _setResults = (
  s: TSearchSuggest,
  { payload: results }: PayloadAction<TSearchSuggest["results"]>,
) => {
  s.results = results;
};

export const _suggestLocality = (
  s: TSearchSuggest,
  {
    payload: { query, fetch = false },
  }: PayloadAction<{ query: TSearchSuggest["query"]; fetch?: boolean }>,
) => {
  s.query = query;

  if (fetch && query) {
    fetchSuggestions({ country: LANG, term: query, levels: LEVELS }).then((v) =>
      store.dispatch(setResults(v.data.addressSuggestions)),
    );
  }
};

export const _setActiveSuggestion = (
  s: TSearchSuggest,
  { payload: index }: PayloadAction<Maybe<number>>,
) => {
  s.activeIndex = index;
};
