import type { CookieConsentConfig } from "vanilla-cookieconsent";
import store from "@/redux/store";
import { setUserPreferencesCookies } from "@/redux/user/preferences";
import pushEvent from "@/utils/other/pushEvent";
import { cookiesAgreed } from "@/utils/dataLayer/events/cookiesAgreed";
import { cookiesDenied } from "@/utils/dataLayer/events/cookiesDenied";

const agree = () => {
  store.dispatch(setUserPreferencesCookies(true));
  pushEvent(cookiesAgreed());
};

const deny = () => {
  store.dispatch(setUserPreferencesCookies(false));
  pushEvent(cookiesDenied());
};

const pluginConfig: CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: "bar",
      position: "bottom",
      equalWeightButtons: false,
      flipButtons: true,
    },
    preferencesModal: {
      layout: "box",
      position: "left",
      equalWeightButtons: false,
      flipButtons: true,
    },
  },
  onFirstConsent: function ({ cookie }) {
    if (cookie.categories.length > 1) {
      agree();
    } else {
      deny();
    }
  },
  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/,
          },
        ],
      },
    },
    marketing: {
      autoClear: {
        cookies: [
          {
            name: /^(_gat|_dc_gtm_)/,
          },
        ],
      },
    },
  },
  /* eslint-disable quotes */
  language: {
    default: "sk",
    translations: {
      sk: {
        consentModal: {
          title: "Web používa cookies",
          description:
            "Na personalizáciu obsahu a reklám, poskytovanie funkcií sociálnych médií a analýzu našej návštevnosti využívame súbory cookie. Informácie o tom, ako náš web používate, zdieľame so svojimi partnermi pre sociálne médiá, inzerciu a analýzy.",
          acceptAllBtn: "Povoliť všetko",
          showPreferencesBtn: "Upraviť nastavenie",
        },
        preferencesModal: {
          title: "Predvoľby súborov cookie",
          acceptAllBtn: "Povoliť všetko",
          acceptNecessaryBtn: "Zakázať všetko",
          savePreferencesBtn: "Uložiť nastavenie",
          closeIconLabel: "Zavrieť",
          sections: [
            {
              title: "Používanie súborov cookie",
              description:
                'Súbory cookie používame na zabezpečenie základných funkcií webu a na zlepšenie vášho online zážitku. Pre každú kategóriu si môžete vybrať, či sa chcete prihlásiť/odhlásiť. Ďalšie podrobnosti týkajúce sa súborov cookie a ďalších citlivých údajov nájdete v úplnom znení <a href="https://www.flatzone.cz/podminky/podminky-uzivani/" class="cc__link">zásady ochrany osobných údajov</a>.',
            },
            {
              title: "Nezbytné cookies",
              description:
                "Tieto súbory cookie sú nevyhnutné pre správne fungovanie našich webových stránok. Bez týchto cookies by web nefungoval správne.",
              linkedCategory: "necessary",
            },
            {
              title: "Analytické a štatistické cookies",
              description:
                "Umožňuje ukladanie informácií (ako sú súbory cookie) súvisiacich s analýzou, napr. dĺžka návštevy.",
              linkedCategory: "analytics",
              cookieTable: {
                headers: {
                  name: "Názov",
                  domain: "Služba",
                  description: "Popis",
                  expiration: "Expirácia",
                },
                body: [
                  {
                    name: "_ga",
                    domain: ".flatzone.sk",
                    description:
                      "Súbor cookie je spojený s Google Analytics - čo je významná aktualizácia bežne používanej analytickej služby Google. Tento súbor cookie sa používa na rozlíšenie jedinečných používateľov priradením náhodne generovaného čísla ako identifikátora klienta. Je súčasťou každého požiadavku na stránku na webe a slúži k výpočtu údajov o návštevníkoch, reláciách a kampaniach pre analytické prehľady webov.",
                    expiration: "2 roky",
                  },
                  {
                    name: "_dc_gtm_*",
                    domain: ".flatzone.sk",
                    description:
                      "Súbor cookie je súčasťou Google Analytics a používa sa na obmedzenie počtu požiadavkov.",
                    expiration: "1 minúta",
                  },
                  {
                    name: "_gid",
                    domain: ".flatzone.sk",
                    description:
                      "Súbor cookie nastavuje Google Analytics. Ukladá a aktualizuje jedinečnú hodnotu pre každú navštívenú stránku a slúži na počítanie a sledovanie zobrazení stránok.",
                    expiration: "1 deň",
                  },
                ],
              },
            },
            {
              title: "Reklamné cookies",
              description:
                "Umožňuje ukladanie informácií (ako sú súbory cookie) súvisiacich s inzerciou.",
              linkedCategory: "marketing",
              cookieTable: {
                headers: {
                  name: "Názov",
                  domain: "Služba",
                  description: "Popis",
                  expiration: "Expirácia",
                },
                body: [
                  {
                    name: "__gads",
                    domain: ".flatzone.sk",
                    description:
                      "Súbor cookie používa spoločnosť Google na rôzne účely. Zahŕňa aj AdSense, ak máte AdSense povolené. Tento súbor cookie je spojený so službou DoubleClick for Publishers od spoločnosti Google. Jeho účelom je sledovať zobrazovanie reklám na webe, za ktoré môže majiteľ získať určitý príjem. Hlavným účelom tohto súboru cookie je cielenie/reklama.",
                    expiration: "nedefinované",
                  },
                  {
                    name: "test_cookie",
                    domain: "*.doubleclick.net",
                    description:
                      "Súbor cookie je nastavený spoločnosťou DoubleClick (vlastníkom je spoločnosť Google), aby určila, či prehliadač návštevníka webu podporuje súbory cookie.",
                    expiration: "12 minút",
                  },
                  {
                    name: "_gcl_au",
                    domain: "*.doubleclick.net",
                    description:
                      "Súbor cookie je nastavený spoločnosťou DoubleClick (vlastníkom je spoločnosť Google), aby určila, či prehliadač návštevníka webu podporuje súbory cookie.",
                    expiration: "30 dní",
                  },
                ],
              },
            },
            {
              title: "Viac informácií",
              description:
                'V prípade akýchkoľvek otázok nás neváhajte <a class="cc__link" href="https://www.flatzone.cz/kontakt/">kontaktovať</a>.',
            },
          ],
        },
      },
    },
  },
};
/* eslint-enable quotes */
export default pluginConfig;
