import { TSearchList } from "@/redux/search/list/types";
import { PayloadAction } from "@reduxjs/toolkit";

export const _setSearchListProjects = (
  s: TSearchList,
  { payload: projects }: PayloadAction<TSearchList["projects"]>,
) => {
  s.projects = projects;
};

export const _setSearchListEstates = (
  s: TSearchList,
  { payload: estates }: PayloadAction<TSearchList["estates"]>,
) => {
  s.estates = estates;
};

export const _setSearchListStats = (
  s: TSearchList,
  { payload: stats }: PayloadAction<TSearchList["stats"]>,
) => {
  s.stats = stats;
};

// export const _setSearchListBanners = (
//   s: TSearchList,
//   { payload: banners }: PayloadAction<TSearchList["banners"]>,
// ) => {
//   s.banners = banners;
// };
