import { OperationChildren } from "@/types/operation";
import f from "./f";

const buildChildrenList = (children: OperationChildren): string => {
  return children
    .map((v) => (typeof v === "string" ? v : f(v[0])(v[1])))
    .join(" ");
};

export default buildChildrenList;
