import { TUserAuth } from "@/redux/user/auth/types";
import cookie from "js-cookie";

const initialState: TUserAuth = {
  userToken:
    cookie.get("userToken") ||
    (typeof localStorage !== "undefined" &&
      localStorage.getItem("userToken")) ||
    "",
  userRefreshToken:
    cookie.get("userRefreshToken") ||
    (typeof localStorage !== "undefined" &&
      localStorage.getItem("userRefreshToken")) ||
    "",
  userData:
    (typeof localStorage !== "undefined" &&
      JSON.parse(localStorage.getItem("userData") || "{}")) ||
    {},
  isLoginFormOpen: false,
  cookies: {},
};

export default initialState;
