import { Maybe } from "@/types/general";

const buildVariableList = (vars: Maybe<Record<string, any>>) => {
  if (vars) {
    const stringifiedVars = Object.entries(vars)
      .map(([name, type]) => `$${name}:${type}`)
      .join(" ");

    return `(${stringifiedVars})`;
  }

  return "";
};

export default buildVariableList;
