import { OperationChildren, OperationProps } from "@/types/operation";
import buildPropsList from "./buildPropsList";
import buildChildrenList from "./buildChildrenList";

const f = (name: string, props?: OperationProps) => {
  const propsOperation = name + (props ? buildPropsList(props) : "");

  return (fields: OperationChildren) =>
    `${propsOperation} {${buildChildrenList(fields)}}`;
};

export default f;
