import buildVariableList from "./buildVariableList";
import { OperationReturn, OperationVars } from "@/types/operation";
import toVariableReducer from "./toVariableReducer";
import { Maybe } from "@/types/general";

const operation = <Vars>(
  name: string,
  operationName: string,
  vars: Maybe<OperationVars<Vars>>,
) => {
  const left = `${name} ${operationName}${buildVariableList(vars)}`;

  return (fields: OperationReturn<Vars>) => {
    const keys = Object.keys(vars || {});
    const variables = keys.reduce(
      (r, i) => toVariableReducer<Vars>(r, i as keyof Vars),
      {} as OperationVars<Vars>,
    );
    const right = fields(variables).join(" ");

    return `${left} {${right}}`;
  };
};

export default operation;
