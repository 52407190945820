import { TSearchShared } from "@/redux/search/shared/types";

const initialState: TSearchShared = {
  query: null,
  settings: null,
  filters: null,
  loading: false,
  type: "list",
  numOfProjects: 0,
  hasChanged: false,
  ignoreEstateImpression: false,
  lastPage: null,
  page: {
    type: null,
    title: null,
  },
};

export default initialState;
