import { TUserPreferences } from "@/redux/user/preferences/types";

const savedPreference =
  typeof localStorage !== "undefined" ? localStorage.getItem("cookies") : null;
const currentPreferences = savedPreference ? savedPreference === "true" : null;

const initialState: TUserPreferences = {
  cookies: typeof localStorage === "undefined" ? true : currentPreferences,
};

export default initialState;
