import { TSearchDetail } from "@/redux/search/detail/types";
import { PayloadAction } from "@reduxjs/toolkit";

export const _setSearchDetailEstates = (
  s: TSearchDetail,
  { payload: estates }: PayloadAction<TSearchDetail["estates"]>,
) => {
  s.estates = estates;
};

export const _setSearchDetailData = (
  s: TSearchDetail,
  { payload: data }: PayloadAction<TSearchDetail["data"]>,
) => {
  s.data = data;
};
