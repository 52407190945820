import { TUserAuth } from "@/redux/user/auth/types";
import { PayloadAction } from "@reduxjs/toolkit";
import cookie from "js-cookie";

export const _setUserToken = (
  s: TUserAuth,
  { payload: userToken }: PayloadAction<TUserAuth["userToken"]>,
) => {
  if (typeof localStorage !== "undefined")
    localStorage.setItem("userToken", `${userToken}`);

  cookie.set("userToken", userToken);

  s.cookies["userToken"] = userToken;
  s.userToken = userToken;
};

export const _setUserRefreshToken = (
  s: TUserAuth,
  { payload: userRefreshToken }: PayloadAction<TUserAuth["userRefreshToken"]>,
) => {
  if (typeof localStorage !== "undefined")
    localStorage.setItem("userRefreshToken", `${userRefreshToken}`);

  cookie.set("userRefreshToken", userRefreshToken);

  s.cookies["userRefreshToken"] = userRefreshToken;
  s.userRefreshToken = userRefreshToken;
};

export const _setUserData = (
  s: TUserAuth,
  { payload: userData }: PayloadAction<TUserAuth["userData"]>,
) => {
  if (typeof localStorage !== "undefined")
    localStorage.setItem("userData", JSON.stringify(userData));
  s.userData = userData;
};
export const _setIsLoginFormOpen = (
  s: TUserAuth,
  { payload: isLoginFormOpen }: PayloadAction<TUserAuth["isLoginFormOpen"]>,
) => {
  s.isLoginFormOpen = isLoginFormOpen;
};

export const _setCookies = (
  s: TUserAuth,
  { payload: cookies }: PayloadAction<Record<any, any>>,
) => {
  s.cookies = cookies;
};
