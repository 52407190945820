import { createSlice } from "@reduxjs/toolkit";
import initialState from "@/redux/search/list/initialState";
import {
  // _setSearchListBanners,
  _setSearchListEstates,
  _setSearchListProjects,
  _setSearchListStats,
} from "@/redux/search/list/reducers";

const name = "searchList" as const;

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setSearchListProjects: _setSearchListProjects,
    setSearchListEstates: _setSearchListEstates,
    setSearchListStats: _setSearchListStats,
    // setSearchListBanners: _setSearchListBanners,
  },
});

export const {
  setSearchListProjects,
  setSearchListEstates,
  setSearchListStats,
  // setSearchListBanners,
} = slice.actions;

export default {
  [name]: slice.reducer,
};
